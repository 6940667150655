import './login.scss'
import { setCourtList, setValidateImg, handleLogin } from './login'
import bgPng from './img/bg.png'
import titlePng from './img/lg_title.png'
import imagePng from './img/lg_image.png'
import usernamePng from './img/lg_username.png'
import passwordPng from './img/lg_password.png'
import validatePng from './img/validate.png'

$(function () {
  $('#img_bg').attr('src', bgPng)
  $('#img_lg_title').attr('src', titlePng)
  $('#img_lg_image').attr('src', imagePng)
  $('#img_lg_username').attr('src', usernamePng)
  $('#img_lg_password').attr('src', passwordPng)
  $('#img_validate').attr('src', validatePng)

  const isCourt = $('#loginMode').val() === 'court'

  if (isCourt) {
    setCourtList()
  }

  $('#validateImg').click(setValidateImg)
  setValidateImg()

  layui.form.render(null, 'login-form')

  layui.form.on('submit(login-submit-form)', (data: any) => {
    const loginData = {
      ...data.field,
      password: data.field.userPwd,
      userPwd: undefined
    }
    handleLogin(isCourt, loginData)
  })
  $('#login-form input').keydown((ev) => {
    if (ev.which === 13) {
      $('#loginBtn').click()
    }
  })
})
